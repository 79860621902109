import firebase from "firebase/compat/app";
import React, { useRef, useEffect, useState } from "react";

import { styled } from "@mui/system";
import { Grid } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import moment from "moment";

import { Link } from "react-router-dom";

import useOnCollection from "../common/useOnCollection";

import Car from "./driver/car";
import Driver from "./driver/driver";
import Test from "./Test";

import { CarData, DriverData } from "../types/data_types";
import { useFilteredDriverData, useFilteredCarData } from "./driverUtil";

const BusDriverGrid = styled(Grid)(({ theme }) => ({
  overflowX: "auto",
  whiteSpace: "nowrap",
}));

const now = moment(new Date().toUTCString());

now.endOf("month");

const Opt = {
  queryFilter: (query: any) => {
    return query.orderBy("order", "asc");
  },
};

type Props = {
  db: firebase.firestore.Firestore;
  company: any;
  lineData: any;
  setHeaderTitle: any;
};

const Drivers: React.FC<Props> = ({
  db,
  company,
  lineData,
  setHeaderTitle,
}) => {
  const props = { db, company, lineData, setHeaderTitle };

  const [current, setCurrent] = useState(now);
  const [dispatchData, setDispatchData] = useState<any>(null);
  const [filterdLineData, setFilterdLineData] = useState<any[]>([]);

  const driverPath = `companies/${company.id}/drivers`;
  const [driverData] = useOnCollection<DriverData>(db, driverPath, Opt);
  const { filteredDriverData } = useFilteredDriverData(driverData);

  const carPath = `companies/${company.id}/cars`;
  const [carData] = useOnCollection<CarData>(db, carPath, Opt);
  const { filteredCarData } = useFilteredCarData(carData);

  const myRef = useRef<
    any & {
      setInitData: any;
      handleChangeCar: any;
      handleChangeDriver: any;
    }
  >();

  useEffect(() => {
    setHeaderTitle("月間運行計画");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const decreaseDate = () => {
    const newCurrent = current.clone();
    newCurrent.add(-1, "month");
    setCurrent(newCurrent);
  };
  const increaseDate = () => {
    const newCurrent = current.clone();
    newCurrent.add(1, "month");
    setCurrent(newCurrent);
  };

  useEffect(() => {
    const tmp = Object.values(lineData).filter((data: any) => {
      return data.shouldSchedule;
    });
    setFilterdLineData(tmp);
  }, [company]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    (async () => {
      if ((filteredCarData || []).length > 0 && filteredDriverData.length > 0) {
        const endDay = current.endOf("month").date();

        const mykey = current.startOf("month").format("YYYY-MM");
        const doc = await db
          .doc(`companies/${company.id}/driverScedule/${mykey}`)
          .get();

        if (doc.exists) {
          if (dispatchData) {
            if (myRef.current !== undefined) {
              myRef.current.setInitData(doc.data());
            }
          }
          setDispatchData(doc.data());
        } else {
          const _dispatchData: any = {};
          for (let i = 0; i < endDay; i++) {
            const key = current
              .startOf("month")
              .add(i, "day")
              .format("YYYY-MM-DD");
            _dispatchData[key] = {};

            filterdLineData.map((line, key2) => {
              // This is super hack for https://app.asana.com/0/1148824507092124/1179238230407493
              const tmpCar = (filteredCarData || []).filter((car: any) => {
                return car.lineId === undefined || car.lineId === line.id;
              });
              const driverOffset =
                key2 === 0 ? 0 : filteredDriverData.length - 1;
              // end of hack
              _dispatchData[key][line.id] = {
                car: tmpCar[0].id,
                driver: filteredDriverData[driverOffset].id,
              };
              return null;
            });
          }
          if (dispatchData) {
            // for update
            if (myRef.current !== undefined) {
              myRef.current.setInitData(_dispatchData);
            }
          }
          setDispatchData(_dispatchData);
        }
      }
    })();
  }, [current, filteredCarData, filteredDriverData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeCar = (key: string, lineId: string, id: string) => {
    if (myRef.current !== undefined) {
      myRef.current.handleChangeCar(key, lineId, id);
    }
  };

  const handleChangeDriver = (key: string, lineId: string, id: string) => {
    if (myRef.current !== undefined) {
      myRef.current.handleChangeDriver(key, lineId, id);
    }
  };

  if (!dispatchData) {
    return <div />;
  }
  const days = Object.keys(dispatchData).sort();

  const reverseDriverData = filteredDriverData.concat();
  reverseDriverData.reverse();

  return (
    <BusDriverGrid container alignItems="stretch" direction="row">
      <h2>
        <button
          onClick={(e) => {
            decreaseDate();
          }}
        >
          &#9664;
        </button>
        {current.format("YYYY-MM")}
        <button
          onClick={(e) => {
            increaseDate();
          }}
        >
          &#9654;
        </button>
      </h2>
      <Test
        ref={myRef}
        dispatchData={dispatchData}
        current={current}
        {...props}
      />
      <Table>
        <TableHead>
          <TableRow style={{ whiteSpace: "nowrap" }}>
            <TableCell>日付</TableCell>
            {filterdLineData.map((line, lineKey) => {
              return (
                <React.Fragment key={lineKey}>
                  <TableCell>車両</TableCell>
                  <TableCell>乗務員</TableCell>
                </React.Fragment>
              );
            })}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody key={Object.keys(dispatchData)[0]}>
          <TableRow style={{ whiteSpace: "nowrap" }}>
            <TableCell></TableCell>
            {filterdLineData.map((line, lineKey) => {
              return (
                <TableCell key={lineKey} colSpan={2}>
                  {line.name}便
                </TableCell>
              );
            })}
          </TableRow>
          {days.map((day, key) => {
            const hasCustom = Object.values(dispatchData[day]).some(
              (data: any) => !!data.custom,
            );
            return (
              <TableRow
                key={key}
                style={{
                  whiteSpace: "nowrap",
                  backgroundColor: hasCustom ? "#ffff00" : "#ffffff",
                }}
              >
                <TableCell>{day}</TableCell>
                {filterdLineData.map((line, lineKey) => {
                  // This is super hack for https://app.asana.com/0/1148824507092124/1179238230407493
                  const tmpCar = (filteredCarData || []).filter((car: any) => {
                    return car.lineId === undefined || car.lineId === line.id;
                  });
                  // end of hack
                  const params = {
                    carData: tmpCar,
                    day,
                    lineId: line.id,
                    handleChangeCar,
                    defaultValue: dispatchData[day][line.id].car,
                  };
                  const params2 = {
                    driverData: filteredDriverData,
//                      lineKey === 1 ? reverseDriverData : filteredDriverData,
                    day,
                    lineId: line.id,
                    handleChangeDriver,
                    defaultValue: dispatchData[day][line.id].driver,
                    revert: lineKey,
                  };

                  return (
                    <React.Fragment key={lineKey}>
                      <TableCell>
                        <Car {...params} />
                      </TableCell>
                      <TableCell>
                        <Driver {...params2} />
                      </TableCell>
                    </React.Fragment>
                  );
                })}
                <TableCell>
                  <Link
                    to={`/admin/reservations/${day.replace(/-/g, "/")}/${
                      filterdLineData[0].id
                    }/0`}
                  >
                    例外処理 <ArrowForwardIcon />
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <h2>
        <button
          onClick={(e) => {
            decreaseDate();
          }}
        >
          &#9664;
        </button>
        {current.format("YYYY-MM")}
        <button
          onClick={(e) => {
            increaseDate();
          }}
        >
          &#9654;
        </button>
      </h2>
    </BusDriverGrid>
  );
};

export default Drivers;
